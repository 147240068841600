"use client";

import React, { useEffect } from "react"
import { Link } from "@heroui/link"
import HiddenEmail from "~/components/email-decoder/encode"
import styles from "./footer.module.css"
// import staticImageLoader from "~/loaders/static-image-loader"
// import Image from "next/image"
// import Map from "./map-light.png"


async function getDecoder(...ids) {
    const decode = (await import("~/components/email-decoder")).default;
    decode(...ids);
}

export default function Footer() {
    useEffect(() => { getDecoder("mail-ft") }, [])

    return (
        <footer className={styles.wrapper}>
            <div className={`${styles.content} max-w-screen-2xl lg:grid-cols-4 grid-cols-2`}>
                <div className="max-sm:hidden"
                    style={{
                        position:"relative"
                    }}
                >
                    <div className="flex hidden lg:inline-flex text-white"
                        style={{
                            gridColumn: "span 2",
                            justifyContent: "space-around",
                        }}
                    >
                        <ul>
                            <li>
                                <h2 className="font-bold mb-2">Finn oss på sosiale medier</h2>
                            </li>
                            <li className="flex" style={{gap: "0.5rem"}}>
                                {/* <Link href="/" className="text-white">
                                    Facebook
                                </Link> */}
                                <Link href="https://www.linkedin.com/company/asendi-as/" className="text-white">
                                    LinkedIn
                                </Link>
                            </li>
                        </ul>
                    </div>
                    {/* <Image
                        src={Map.src}
                        loader={staticImageLoader}
                        alt="Kart over Husly bosenter og Moelv"
                        sizes="all"
                        style={{
                            objectFit: "contain"
                        }}
                        fill
                    /> */}
                </div>
                <div className="flex hidden lg:inline-flex text-white"
                    style={{
                        gridColumn: "span 2",
                        justifyContent: "space-around"
                    }}
                >
                    <ul>
                        <li>
                            <h2 className="font-bold mb-2">Sider</h2>
                        </li>
                        <li>
                            <Link href="/" className="text-white">
                                Hjem
                            </Link>
                        </li>
                        <li>
                            <Link href="/blog/" className="text-white">
                                Blogg
                            </Link>
                        </li>
                        <li>
                            <Link href="/kontakt/" className="text-white">
                                Kontakt Oss
                            </Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <h2 className="font-bold mb-2">Personvern</h2>
                        </li>
                        <li>
                            <Link href="/privacy" className="text-white">
                                Personvernerklæring
                            </Link>
                        </li>
                        <li>
                            <Link href="/privacy/cookies" className="text-white">
                                Informasjonskapsler (cookies)
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="text-center lg:text-right col-span-2 lg:col-span-1">
                    <h2 className="font-bold mb-4 text-white">Kontakt oss</h2>
                    <div>
                        <div className="mb-2">
                            <p className="text-white">Ascendi AS</p>
                            <p className="text-white/60 font-medium">Org. 931 247 778</p>
                        </div>
                        <div className="mb-2">
                            <p className="text-white"><HiddenEmail id="mail-ft" domain="ascendi.no">hei@ascendi.no</HiddenEmail></p>
                            <p className="text-white/60 font-medium"><a href="tel:+4722330100">+47 223 30 100</a></p>
                        </div>
                        <div>
                            <a href="http://maps.apple.com/?daddr=Ascendi+AS,+Feiringvegen+949,+2093+Feiring" target="_blank" rel="noopener noreferrer">
                                <p className="text-white">Feiringvegen 949</p>
                                <p className="text-white/60 font-medium">2093 Feiring</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ textAlign:"center" }}
                className="text-medium text-white/50"
            >
                &copy; 2025 Ascendi AS. Alle rettigheter forbeholdes.
            </div>
        </footer>
    )
}