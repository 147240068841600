import(/* webpackMode: "eager", webpackExports: ["HeroUIProvider"] */ "/usr/src/node_modules/@heroui/system/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/usr/src/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/src/components/footer/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/src/components/navbar/index.js");
